var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('invoice-recalculate'),_c('v-blacker-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-layout',[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Records"}},[_c('v-blacker-table',{attrs:{"headers":_vm.invoiceRecordsListHeaders,"items":_vm.invoiceRecords},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [_c('v-layout',{attrs:{"align-center":""}},[_c('router-link',{staticClass:"blue--text darken-1 mr-1",attrs:{"to":{
              name: 'order',
              params: { id: id },
            }}},[_vm._v(" "+_vm._s(id)+" ")]),_c('ir-invoice-order-msbc-runner',{attrs:{"order-id":id}})],1)]}},{key:"dates",fn:function(ref){
            var ref_item = ref.item;
            var dateCreated = ref_item.dateCreated;
            var dateShipped = ref_item.dateShipped;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(dateCreated,'local', '-'))+" / "+_vm._s(_vm._f("formatDate")(dateShipped,'local', '-'))+" ")]}},{key:"status",fn:function(ref){
            var status = ref.item.status;
return [_vm._v(" "+_vm._s(_vm.invoiceRecordsStatuses[status] || '-')+" ")]}},{key:"product",fn:function(ref){
            var ref_item = ref.item;
            var productType = ref_item.productType;
            var quantity = ref_item.quantity;
return [_vm._v(" "+_vm._s(productType)+" "),(quantity)?_c('span',[_vm._v(" ("+_vm._s(quantity)+") ")]):_vm._e()]}},{key:"costs",fn:function(ref){
            var item = ref.item;
return [_c('ir-currency',{attrs:{"value":item.printCustomerCost,"current-currency":item.printCustomerCurrency,"rate":item.exchangeRateProviderAgainstCustomer}}),_vm._v(" / "),_c('ir-currency',{attrs:{"value":item.printProviderCost,"current-currency":item.printCustomerCurrency,"base-currency":item.originalPrintProviderCurrency,"rate":item.exchangeRateProviderAgainstCustomer,"converted-in-tooltip":""}}),_vm._v(" / "),_c('ir-currency',{attrs:{"value":item.shipCost,"current-currency":item.printCustomerCurrency,"base-currency":item.originalPrintProviderCurrency,"rate":item.exchangeRateProviderAgainstCustomer,"converted-in-tooltip":""}}),_vm._v(" / "),_c('ir-currency',{attrs:{"value":item.estimatedShipCost,"current-currency":item.printCustomerCurrency,"rate":item.exchangeRateProviderAgainstCustomer}})]}},{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('ir-invoice-record-row-actions',{on:{"click:history":function($event){return _vm.openRecordHistory(item.id)},"click:invoices":function($event){return _vm.openInvoices(item.orderId)},"click:statements":function($event){return _vm.openRecordHistory(item.id)}}})]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1),_c('ir-invoice-record-history',{ref:"invoiceRecordHistory"}),_c('ir-invoices-viewer',{ref:"invoicesViewer"}),_c('ir-statements-viewer',{ref:"statementsViewer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }