<template>
  <vbt-dialog
    v-model="dialog"
    :title="title"
    hide-submit-btn
    width="80%"
  >
    <ir-statements-list
      :items="items"
      :page-size="items.length"
    />
  </vbt-dialog>
</template>

<script>
import { IrStatementsList } from '@modules/costs/statements';

export default {
  name: 'IrStatementsViewer',

  components: {
    IrStatementsList,
  },

  data() {
    return {
      items: [],
      orderId: '',
      dialog: false,
    };
  },

  computed: {
    title() {
      return `Statements for order ${this.orderId}`;
    },
  },

  methods: {
    $_openDialog(items, orderId) {
      this.$set(this, 'items', items);
      this.orderId = orderId;
      this.dialog = true;
    },
  },
};
</script>
