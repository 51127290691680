var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-dialog',{attrs:{"title":"Invoice Record History","width":"90%","hide-submit-btn":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page-size":_vm.items.length},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var dateCreated = ref.item.dateCreated;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateCreated))+" ")]}},{key:"dateUpdated",fn:function(ref){
var dateUpdated = ref.item.dateUpdated;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateUpdated))+" ")]}},{key:"dateShipped",fn:function(ref){
var dateShipped = ref.item.dateShipped;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateShipped))+" ")]}},{key:"costs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.printCustomerCost || '-')+" / "+_vm._s(item.printProviderCost || '-')+" / "+_vm._s(item.shipCost || '-')+" / "+_vm._s(item.estimatedShipCost || '-')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }