import { mapGetters } from 'vuex';

import { generateArrayFromString, generateXHRError } from '@helpers';

export default {
  name: 'InvoiceRecalculate',
  data() {
    return {
      dates: ['', ''],
      recalcByOrders: false,
      ordersStr: '',
      accountId: 0,
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountsOptions']),

    ordersArr() {
      return generateArrayFromString(this.ordersStr || '');
    },
    disableSubmitForm() {
      return (this.recalcByOrders ? !this.ordersArr.length : !this.dates[0]) || !this.accountId;
    },
  },
  methods: {
    showRecalculatePrompt() {
      this.$VBlackerTheme.alert.warning({ text: 'Recalculate?' }, this.recalculateInvoice);
    },
    async recalculateInvoice() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        const url = `invoices/${this.recalcByOrders ? 'recalcByOrders' : 'recalculate'}`;
        const data = this.recalcByOrders ? this.ordersArr : {};
        const params = {};

        if (this.recalcByOrders) {
          Object.assign(params, { printCustomer: this.accountId });
        } else {
          Object.assign(params, {
            account: this.accountId,
            startDate: this.dates[0],
            endDate: `${this.dates[1]} 23:59:59`,
          });
        }

        await this.$http.post(url, data, { params });
        this.resetFormData();
        this.$VBlackerTheme.notification.success('Invoice successfully recalculated');
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },

    clearRecalculation() {
      this.$VBlackerTheme.alert.warning({ text: 'Clear recalculate queue?' }, async () => {
        this.$VBlackerTheme.openLoadingSpinner();

        try {
          await this.$http.post('invoices/salesorders/recalculate/stop');

          this.$VBlackerTheme.notification.success('Recalculate queue cleared');
        } catch (e) {
          this.$VBlackerTheme.notification.error(generateXHRError(e));
        } finally {
          this.$VBlackerTheme.closeLoadingSpinner();
        }
      });
    },

    resetFormData() {
      this.recalcByOrders = false;
      this.accountId = 0;
      this.ordersStr = '';
      this.dates = ['', ''];
    },
  },
};
