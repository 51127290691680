<template>
  <v-menu
    close-delay="100"
    nudge-left="30"
    nudge-top="25"
    open-on-hover
    left
  >
    <template #activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon>
          more_vert
        </v-icon>
      </v-btn>
    </template>

    <v-layout column>
      <v-btn
        small
        @click="openHistory"
      >
        History
      </v-btn>

      <v-btn
        small
        @click="openInvoices"
      >
        Invoices
      </v-btn>

      <v-btn
        small
        @click="openStatements"
      >
        Statements
      </v-btn>
    </v-layout>
  </v-menu>
</template>

<script>
export default {
  name: 'IrInvoiceRecordRowActions',

  methods: {
    openHistory() {
      this.$emit('click:history');
    },

    openInvoices() {
      this.$emit('click:invoices');
    },

    openStatements() {
      this.$emit('click:statements');
    },
  },
};
</script>
