<template>
  <vbt-dialog
    v-model="dialog"
    title="Invoice Record History"
    width="90%"
    hide-submit-btn
  >
    <vbt-table
        :headers="headers"
        :items="items"
        :page-size="items.length"
    >
      <template #dateCreated="{ item: { dateCreated } }">
        {{ formatDisplayDate(dateCreated) }}
      </template>

      <template #dateUpdated="{ item: { dateUpdated } }">
        {{ formatDisplayDate(dateUpdated) }}
      </template>

      <template #dateShipped="{ item: { dateShipped } }">
        {{ formatDisplayDate(dateShipped) }}
      </template>

      <template #costs="{ item }">
        {{ item.printCustomerCost || '-' }}
        /
        {{ item.printProviderCost || '-' }}
        /
        {{ item.shipCost || '-' }}
        /
        {{ item.estimatedShipCost || '-' }}
      </template>
    </vbt-table>
  </vbt-dialog>
</template>

<script>
import { formatDisplayDate } from '@helpers';

const headers = Object.freeze([
  { text: 'Product Type', value: 'productType' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Status', value: 'status' },
  { text: 'Costs (PC / PP / Ship / Estimated Ship)', value: 'costs' },
  { text: 'Date Created', value: 'dateCreated' },
  { text: 'Date Updated', value: 'dateUpdated' },
  { text: 'Date Shipped', value: 'dateShipped' },
]);

export default {
  name: 'IrInvoiceRecordHistory',

  data() {
    return {
      dialog: false,
      items: [],
      headers,
    };
  },

  methods: {
    $_openDialog(items) {
      this.items = items;
      this.dialog = true;
    },

    formatDisplayDate,
  },
};
</script>
