<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          icon
          x-small
          @click="sendToMSBC"
        >
          <v-icon color="success">
            play_circle_filled
          </v-icon>
        </v-btn>
      </span>
    </template>
    Send to MSBC
  </v-tooltip>
</template>

<script>
import { generateXHRError } from '@helpers';

export default {
  name: 'IrInvoiceOrderMsbcRunner',
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    sendToMSBC() {
      this.$VBlackerTheme.alert.warning({
        text: `Send order ${this.orderId} to MSBC?`,
        width: '600px',
      }, async () => {
        this.$VBlackerTheme.openLoadingSpinner();
        try {
          await this.$http.post(`msbc/orders/${this.orderId}`);
          this.$VBlackerTheme.notification.success('Order successfully sent to MSBC');
        } catch (e) {
          this.$VBlackerTheme.notification.error(generateXHRError(e));
        } finally {
          this.$VBlackerTheme.closeLoadingSpinner();
        }
      });
    },
  },
};
</script>
