import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import { wrapToLoadingFn } from '@helpers';

import { InvoicesApiService } from '@modules/costs/invoices';
import { StatementsApiService } from '@modules/costs/statements';

import InvoiceRecalculate from '../../_components/invoiceRecalculate';
import IrInvoiceOrderMsbcRunner from '../../_components/IrInvoiceOrderMsbcRunner.vue';
import IrInvoiceRecordRowActions from '../../_components/IrInvoiceRecordRowActions.vue';
import IrInvoiceRecordHistory from '../../_components/IrInvoiceRecordHistory.vue';
import IrInvoicesViewer from '../../_components/IrInvoicesViewer.vue';
import IrStatementsViewer from '../../_components/IrStatementsViewer.vue';

const dataLoadingOptions = { getterName: 'getInvoiceRecords' };
const paginationOptions = { itemsName: 'invoiceRecords' };
const searchOptions = {
  defaultRouteQuery: {
    pageNum: '1',
  },
  initialSearchDataStates: [
    'customerId', 'providerId', 'orderId', 'dates',
    'zeroCost', 'zeroShipCost', 'zeroEstimatedShipCost',
  ],
  customTypes: {
    customerId: 'number',
    providerId: 'number',
    dates: 'array',
    zeroCost: 'booleanString',
    zeroShipCost: 'booleanString',
    zeroEstimatedShipCost: 'booleanString',
  },
};
const invoiceRecordsListHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Print Client', value: 'printCustomer' },
  { text: 'Print Provider', value: 'printProvider' },
  { text: 'Product', value: 'product' },
  { text: 'Status', value: 'status' },
  { text: 'Dates (Created / Ship)', value: 'dates' },
  { text: 'Costs (PC / PP / Ship / Estimated Ship)', value: 'costs', width: '12%' },
  { text: '', value: 'actions', width: '5%' },
]);

export default {
  name: 'InvoiceRecords',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  components: {
    InvoiceRecalculate,
    IrInvoiceOrderMsbcRunner,
    IrInvoiceRecordRowActions,
    IrInvoiceRecordHistory,
    IrInvoicesViewer,
    IrStatementsViewer,
  },

  data() {
    return {
      invoiceRecordsListHeaders,
      invoiceRecordsStatuses: Object.freeze({
        0: 'Created',
        1: 'PriceCalculated',
        2: 'ShippingCostSubmited',
        3: 'Holded',
        10: 'Deleted',
      }),
    };
  },

  computed: {
    ...mapState('invoiceRecords', ['invoiceRecords', 'invoiceRecordHistory']),
    ...mapGetters('accounts', ['clientsOptions', 'providersOptions']),

    searchFieldsConfig() {
      return [
        [
          {
            value: 'customerId',
            items: this.clientsOptions,
            label: 'Client',
            type: 'combobox',
            flex: 'lg6',
          },
          {
            value: 'providerId',
            items: this.providersOptions,
            label: 'Provider',
            type: 'combobox',
            flex: 'lg6',
          },
          {
            value: 'orderId', label: 'Order ID', type: 'text', flex: 'lg6',
          },
          {
            value: 'dates', label: 'Date Range', type: 'daterange', flex: 'lg6',
          },
        ],
        [
          {
            value: 'zeroCost', label: 'Zero Cost', type: 'toggle', flex: 'zero-cost-toggle',
          },
          {
            value: 'zeroShipCost', label: 'Zero Ship Cost', type: 'toggle', flex: 'zero-ship-cost-toggle',
          },
          {
            value: 'zeroEstimatedShipCost', label: 'Zero Estimated Ship Cost', type: 'toggle', flex: 'zero-estimated-ship-cost-toggle',
          },
        ],
      ];
    },
  },
  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),

    ...mapActions('invoiceRecords', ['getInvoiceRecords', 'getInvoiceRecordHistory']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    openRecordHistory(id) {
      this.wrapToLoadingFn({
        req: this.getInvoiceRecordHistory.bind({}, id),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          if (this.invoiceRecordHistory.length) {
            this.$refs.invoiceRecordHistory.$_openDialog(this.invoiceRecordHistory);
          } else {
            this.$VBlackerTheme.notification.warning('Empty data');
          }
        },
      });
    },

    openInvoices(orderId) {
      this.wrapToLoadingFn({
        req: InvoicesApiService.getInvoices.bind({}, { orderId, pageSize: 100 }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: (items) => {
          if (items.length) {
            this.$refs.invoicesViewer.$_openDialog(items, orderId);
          } else {
            this.$VBlackerTheme.notification.warning('Empty data');
          }
        },
      });
    },

    openStatements(orderId) {
      this.wrapToLoadingFn({
        req: StatementsApiService.getStatements.bind({}, { orderId, pageSize: 100 }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: (items) => {
          if (items.length) {
            this.$refs.statementsViewer.$_openDialog(items, orderId);
          } else {
            this.$VBlackerTheme.notification.warning('Empty data');
          }
        },
      });
    },

    wrapToLoadingFn,
  },
};
